import { graphql, PageProps } from 'gatsby';
import 'twin.macro';
import BaseLayout from '../layouts/base';
import { GetArtikelPerizinanBerusahaDataQuery } from '../graphql-types';
import { Helmet } from 'react-helmet';
import React from 'react';
import ArticleList from '../components/article-list';
import { generateTag } from '../utils/helper';

const ArtikelPerizinanBerusaha: React.FC<PageProps<GetArtikelPerizinanBerusahaDataQuery>> = ({
  data,
  location,
}) => {
  const pageInfo = data.allContentfulArtikel.pageInfo;
  const tags = data.contentfulTags.tags;
  const tagTitle = data.contentfulTags.title;

  const logo = data?.logo?.childrenImageSharp[0]?.fluid;

  // SEO data
  const siteMeta = data.site?.siteMetadata;
  const canonical = `${siteMeta.url}${location.pathname}`;

  const popularArticles = data?.allPopularArtikel.edges.map(a => a.node);
  const articles = data.allContentfulArtikel.edges.map(a => a.node);

  const seo = data.allContentfulSeoHeader.nodes[0];
  const desc =
    generateTag(seo?.description?.description, {
      name: 'Perizinan Berusaha',
      page: pageInfo?.currentPage,
    }) || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta';
  const title =
    generateTag(seo?.title, {
      name: 'Perizinan Berusaha',
      page: pageInfo?.currentPage,
    }) || 'Artikel Perizinan Berusaha - Easybiz';

  const jsonLd = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        url: canonical,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${siteMeta.url}/search/?query={search_term_string}`,
          },
          'query-input': 'required name=search_term_string',
        },
      },
    ],
  };

  return (
    <BaseLayout logo={logo} pathname={location.pathname}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={seo?.keyword || 'Perizinan Berusaha'} />
        <meta
          name="robots"
          content={
            seo?.robots ||
            'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          }
        />
        <link rel="canonical" href={canonical} />
        <meta name="author" content={seo?.author || 'Easybiz'} />
        <meta name="publisher" content={seo?.publisher || 'Easybiz'} />
        <meta property="og:locale" content={seo?.oglocale || 'en_US'} />
        <meta property="og:type" content={seo?.ogtype || 'website'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={`${siteMeta.url}/category/perizinan-berusaha`} />
        <meta property="og:site_name" content="Easybiz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo?.author || 'Easybiz'} />
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <ArticleList
        pageInfo={pageInfo}
        articles={articles}
        popularArticles={popularArticles}
        category={'Perizinan Berusaha'}
        tagTitle={tagTitle}
        tags={tags}
      ></ArticleList>
    </BaseLayout>
  );
};

export default ArtikelPerizinanBerusaha;

export const query = graphql`
  query getArtikelPerizinanBerusahaData($limit: Int!, $skip: Int!) {
    contentfulTags: contentfulTags {
      title
      tags {
        title
        popular
      }
    }
    allContentfulSeoHeader(filter: { identifier: { eq: "category" } }) {
      nodes {
        title
        description {
          description
        }
        keyword
        language
        robots
        author
        publisher
        updatedAt
        oglocale
        ogtype
      }
    }
    allPopularArtikel: allContentfulArtikel(filter: { popularArticle: { eq: true } }, limit: 3) {
      nodes {
        uid
      }
      edges {
        node {
          uid
          title
          category
          tags
          shortDescription {
            raw
          }
          info
          date
          img {
            file {
              url
              fileName
            }
            fluid(maxHeight: 70, maxWidth: 125) {
              ...ContentfulImageFluidFields
            }
          }
        }
      }
    }
    allContentfulArtikel(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { category: { eq: "Perizinan Berusaha" } }
    ) {
      nodes {
        uid
      }
      edges {
        node {
          uid
          title
          category
          tags
          shortDescription {
            raw
          }
          author
          info
          date
          img {
            file {
              url
              fileName
            }
            fluid(maxHeight: 70, maxWidth: 125) {
              ...ContentfulImageFluidFields
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
